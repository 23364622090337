@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

/* roboto-100 - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 100;
    src: url("../fonts/roboto-v30-latin-100.eot"); /* IE9 Compat Modes */
    src: local(''),
    url("../fonts/roboto-v30-latin-100.eot?#iefix") format('embedded-opentype'), /* IE6-IE8 */
    url("../fonts/roboto-v30-latin-100.woff2") format('woff2'), /* Super Modern Browsers */
    url("../fonts/roboto-v30-latin-100.woff") format('woff'), /* Modern Browsers */
    url("../fonts/roboto-v30-latin-100.ttf") format('truetype'), /* Safari, Android, iOS */
    url("../fonts/roboto-v30-latin-100.svg") format('svg'); /* Legacy iOS */
}

html, body {
    height: 100%;
}

img {
    display: inline-block;
    width: 100%;
    padding: 20px;
}

.container-wrapper {
    padding: 30px 15px;
}

.container-min {
    min-height: 0;
    padding: 20px;
}

.container-light {
    background: #ffffff;
    color: #000000;
}

.container-dark {
    background: #1b1b1b;
    color: #ffffff;
}

.container-darker {
    background: #000000;
    color: #ffffff;
}

.container-secondary {
    background: #dddddd;
}

.logo {
    height: 55px;
    width: unset;
    padding: 0;
}

.carousel .carousel-control-next, .carousel .carousel-control-prev {
    filter: invert(100%);
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
    display: block;
}

.btn-outline-purple {
    border-color: #7347ff;
    color: #7347ff;
}

.btn-outline-purple:disabled {
    color: #7347ff;
    background-color: transparent;
}

.btn-outline-purple:not(:disabled):hover {
    background: #7347ff;
    color: #ffffff;
}

.btn-outline-purple:focus {
    box-shadow: 0 0 0 0.2rem rgba(115, 71, 255, .25);
}

.form-control:focus {
    box-shadow: 0 0 0 0.2rem rgba(115, 71, 255, .25);
    border-color: #7347ff;
}

[class*="col-"] {
    padding: 10px;
}

.w-200px {
    width: 200px;
}

.slick-prev:before, .slick-next:before {
    color: #000000;
}

.slick-prev {
    left: 0;
}

.slick-next {
    right: 0;
}

.slick-track {
    display: flex;
}

.slick-track .slick-slide {
    display: flex;
    height: auto;
    align-items: center;
    justify-content: center;
}

.line {
    height: 1px;
    margin: 15px auto 10px auto;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0), #000, rgba(0, 0, 0, 0));
    width: 60%;
}

#cookie_info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.95);
    padding: 10px;
    color: #fff;
    box-shadow: 0 0 5px #fff;
}